// @description Provider component for MUI theming

import { Theme, ThemeOptions, alpha, darken } from '@mui/material/styles';

export const themeColorTransition = 'color var(--theme-transition-duration) linear, background-color var(--theme-transition-duration) linear';

export const isWindows = typeof window !== 'undefined' && window.navigator.platform?.toLowerCase().includes('win');

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        grey: true;
    }
}

declare module '@mui/material/SvgIcon' {
    interface SvgIconPropsColorOverrides {
        functionalBlue: true;
    }
}

declare module '@mui/material/IconButton' {
    interface IconButtonPropsColorOverrides {
        functionalBlue: true;
    }
}

const overrides = (theme: Theme): ThemeOptions => {
    const light = theme.palette.mode === 'light';
    return {
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    body: {
                        '--theme-transition-duration': '0ms',
                        transition: themeColorTransition,
                        // Apply color and background only to main div, so that other features that work on body are not affected (ie. copy to clipboard)
                        backgroundColor: 'initial',
                        color: 'initial',
                        '&>#root': {
                            backgroundColor: theme.palette.background.default,
                            color: theme.palette.text.primary,
                        },
                        ...theme.typography.body2,
                    },
                    a: {
                        textDecoration: 'none',
                        color: 'inherit',
                        '&:visited': {
                            color: 'inherit',
                        },
                        // '&:hover': {
                        //     textDecoration: 'underline',
                        // },
                    },
                    mark: {
                        backgroundColor: alpha(theme.palette.primary.light, 0.4)
                    },
                    // Scrollbar styling
                    '*': isWindows ? undefined : {
                        scrollbarWidth: 'thin',
                        scrollbarColor: `${theme.palette.divider} transparent`,
                        /* total width */
                        '&::-webkit-scrollbar': {
                            backgroundColor: 'transparent',
                            width: 8,
                        },
                        /* background of the scrollbar except button or resizer */
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: 'transparent',
                        },
                        /* scrollbar itself */
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: theme.palette.divider,
                            borderRadius: 4,
                        },
                        /* set button(top and bottom of the scrollbar) */
                        '&::-webkit-scrollbar-button': {
                            display: 'none',
                        },
                    },
                    '@keyframes product-tour-highlight': {
                        from: {
                            boxShadow: `0 0 8px 1px ${light ? theme.palette.primary.dark : theme.palette.primary.light}`,
                        },
                        to: {
                            boxShadow: `0 0 1px 1px ${light ? theme.palette.primary.dark : theme.palette.primary.light}`,
                        },
                    },
                    '.disable-pointer-events': {
                        pointerEvents: 'none',
                    }
                }
            },
            // Keep all component overrides alphabetically sorted please
            MuiButton: {
                styleOverrides: {
                    containedPrimary: {
                        backgroundColor: theme.palette.primary.dark,
                        '&:hover': {
                            backgroundColor: darken(theme.palette.primary.dark, 0.1),
                        },
                    },
                },
                variants: [
                    {
                        props: { variant: 'contained', color: 'grey' },
                        style: {
                            color: theme.palette.getContrastText(theme.palette.grey[300])
                        }
                    },
                    {
                        props: { variant: 'outlined', color: 'grey' },
                        style: {
                            color: theme.palette.text.secondary,
                            borderColor: theme.palette.mode === 'light'
                                ? theme.palette.text.secondary // 'rgba(0, 0, 0, 0.23)'
                                : 'rgba(255, 255, 255, 0.23)',
                            '&.Mui-disabled': {
                                border: `1px solid ${theme.palette.action.disabledBackground}`
                            },
                            '&:hover': {
                                borderColor: theme.palette.mode === 'light'
                                    ? 'rgba(0, 0, 0, 0.23)'
                                    : 'rgba(255, 255, 255, 0.23)',
                                backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity),
                            }
                        }
                    },
                    {
                        props: { color: 'grey', variant: 'text' },
                        style: {
                            color: theme.palette.text.primary,
                            '&:hover': {
                                backgroundColor: alpha(
                                    theme.palette.text.primary,
                                    theme.palette.action.hoverOpacity
                                )
                            }
                        }
                    },
                ],
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        '&.product-tour-highlight': {
                            animation: 'product-tour-highlight 500ms infinite ease-in-out alternate',
                        },
                    },
                }
            },
            MuiChip: {
                styleOverrides: {
                    avatar: {
                        width: 30,
                        height: 30,
                        marginLeft: 1,
                    },
                    avatarSmall: {
                        marginLeft: 1,
                        width: 22,
                        height: 22,
                    },
                }
            },
            MuiFormHelperText: {
                styleOverrides: {
                    contained: {
                        marginLeft: 0,
                    }
                }
            },
            MuiIconButton: {
                styleOverrides: {
                }
            },
            MuiInputAdornment: {
                styleOverrides: {
                    // This style override makes icon button adornments locate themselves fully over the underline and not offset to the outside
                    standard: {
                        '&.MuiInputAdornment-positionStart > .MuiIconButton-edgeStart': {
                            marginLeft: 0,
                        },
                        '&.MuiInputAdornment-positionEnd > .MuiIconButton-edgeStart': {
                            marginRight: 0,
                        }
                    },
                }
            },
            MuiMenu: {
                styleOverrides: {
                    root: {
                        zIndex: theme.zIndex.modal + 10,
                    },
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        transition: themeColorTransition,
                        backgroundImage: 'unset', // Disable opacity based on the elevation in dark mode
                    }
                }
            },
            MuiSelect: {
                defaultProps: {
                    variant: 'standard',
                },
            },
            MuiTextField: {
                defaultProps: {
                    variant: 'standard',
                },
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                        hyphens: 'auto',
                    },
                }
            },
        },
    };
};
export default overrides;
