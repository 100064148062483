// @description Lazy loads the app while showing a loading animation

import React from 'react';
import { observable, action } from 'mobx';
import {
    CircularProgress,
    CssBaseline,
    Typography,
} from '@mui/material';

import getFirstBrowserLanguage from '@dirico/utils/translator/getBrowserLanguage';
import Logger from '@dirico/utils/Logger';

import { VStack } from '@dirico/material/Stack';
import namedObserver from '@dirico/components/namedObserver';
import ShowAfterDelay from '@dirico/components/ShowAfterDelay';

import createApplicationRoot from '@dirico/frontend-core/core/createApplicationRoot';

import translationMap from '@dirico/chat-application/assets/i18n/appLoad';
import { appLoadError } from '@dirico/chat-application/globalState';

import AppThemeProvider from '@dirico/chat-frontend/core/theme/AppThemeProvider';

import './AppLoader.scss';

// WARNING: Do not include any application core references here as this application is responsible for showing the splash screen

const LazyApp = createApplicationRoot(
    () => import(/* webpackChunkName: "core", webpackPreload: true */ './ChatApplicationRoot')
        .then(async ({ createApplicationResource, ChatApplicationLoader: ChatApplication }) => {
            const appLoader = createApplicationResource();
            // Await initialization of application
            await appLoader.getAsync();
            return {
                appLoader,
                node: <ChatApplication loader={appLoader} />,
            };
        }),
    (accept) => module.hot!.accept('./ChatApplicationRoot', accept)
);

const browserLanguage = getFirstBrowserLanguage(Object.keys(translationMap)) as keyof typeof translationMap || 'en-US';
const translations = translationMap[browserLanguage];

const logMessages: string[] = observable.array();
if (process.env.NODE_ENV !== 'production') {
    Logger.hooks.add({
        log: action((data) => {
            logMessages.unshift(`[${data.loggerName}] ${data.message}`);
            if (logMessages.length > 30) {
                logMessages.length = 30;
            }
        })
    });
}

export const ChatLoaderSplashScreen = namedObserver('ChatLoaderSplashScreen', () => {
    const error = appLoadError.get();
    return (
        <ShowAfterDelay delay={500}>
            <VStack verticalPosition='middle' horizontalAlign='center' spacing={4} sx={{ height: '100%' }}>
                <CircularProgress size={64} color='primary' />
                <Typography variant='h4' align='center' style={{ whiteSpace: 'pre-wrap' }}>
                    {error === 'network' ? translations.noNetwork :
                        error === 'api' ? translations.noApi :
                            error === 'no_teams' ? translations.noApi :
                                translations.loading
                    }
                </Typography>
                {process.env.NODE_ENV !== 'production' && (
                    <pre style={{ fontSize: '10px' }}>{logMessages.join('\n')}</pre>
                )}
            </VStack>
        </ShowAfterDelay>
    );
});

const AppLoader = () => {
    return (
        <AppThemeProvider>
            <CssBaseline />
            <React.Suspense fallback={<ChatLoaderSplashScreen />}>
                <LazyApp />
            </React.Suspense>
        </AppThemeProvider>
    );
};

export default AppLoader;
