
export interface AppLoadTranslations {
    readonly loading: string;
    readonly noNetwork: string;
    readonly noApi: string;
    readonly noTeams: string;
}

type Locales = 'de-DE' | 'en-US';

const translations: Record<Locales, AppLoadTranslations> = {
    'de-DE': {
        loading: 'Bitte warte einen Moment, während der Chat lädt...',
        noNetwork: 'Es besteht ein Problem mit der Internetverbindung.\nWir verbinden dich, sobald die Verbindung wiederhergestellt wurde.',
        noApi: 'Es scheint ein Problem zu geben.\nBitte warte ein paar Minuten bevor du es erneut versuchst.',
        noTeams: 'Dein Nutzer wurde nicht für den Chat freigeschaltet.',
    },
    'en-US': {
        loading: 'Please wait a moment while the chat is loading...',
        noNetwork: 'No internet connection.\nWe will connect you once your connection has been reestablished.',
        noApi: 'There seems to be an issue.\nPlease wait a few minutes before trying again.',
        noTeams: 'Your user has not been granted access to chat.',
    },
};

export default translations;
