// @description Provider component for MUI theming
/* eslint-disable sonarjs/no-duplicate-string */

import React from 'react';
import tinycolor from 'tinycolor2';
import { merge } from 'lodash';
import { observable, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { ThemeProvider } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
import type { Theme, Theme as MuiTheme, ThemeOptions } from '@mui/material/styles';
import grey from '@mui/material/colors/grey';

import StyledEngineProvider from '@dirico/material/material-ui/StyledEngineProvider';

import { createThemeWithOverrides, isSimplePaletteColorOptions, pxToRem } from '@dirico/frontend-core/material-ui/muiThemeUtils';

import themeOverrides from './themeOverrides';

// WARNING: BE CAREFUL TO KEEP IMPORTS TO A MINIMUM HERE, AS THIS IS FILE IS INCLUDED IN THE LOADING SCREEN

const lsThemeKey = 'theme';
const lsThemePrimaryKey = 'theme-primary';
const lsThemeSecondaryKey = 'theme-secondary';

declare module '@emotion/react' {
    export interface Theme extends MuiTheme { }
}

declare module '@mui/material/styles/createPalette' {

    // Theme Options

    interface PaletteOptions {
        userStatus?: Record<string, string>;
    }

    interface SimplePaletteColorOptions {
        background?: string;
    }

    // Theme Result

    interface Palette {
        userStatus: Record<string, string>;
        functionalBlue: PaletteColor;
    }

    interface PaletteColor {
        background?: string;
    }
}

const defaultThemeOptions: ThemeOptions = {
    typography: {
        h1: {
            fontSize: pxToRem(20)
        },
        h2: {
            fontSize: pxToRem(32)
        },
        h3: {
            fontSize: pxToRem(28)
        },
        h4: {
            fontSize: pxToRem(24)
        },
        h5: {
            fontSize: pxToRem(20)
        },
        h6: {
            fontSize: pxToRem(18)
        },
        body1: {
            fontSize: pxToRem(12)
        },
        caption: {
            fontSize: pxToRem(10)
        },
    },
    palette: {
        userStatus: {
            'online': '#43b581',
            'offline': '#a6a6a6',
            'away': '#ff8000',
        },
        grey: {
            // Required overrides to make grey-variant button work correctly
            main: grey[300],
            dark: grey[400],
        } as any,
    }
};

function createTheme(themeOptions: ThemeOptions, overrides?: (theme: Theme) => ThemeOptions) {
    const mergedOptions = merge({ palette: {} }, defaultThemeOptions, themeOptions);

    // Generate new colors
    mergedOptions.palette.primary = mergedOptions.palette.primary || { main: '#3A81C4' };
    if (mergedOptions.palette.primary && isSimplePaletteColorOptions(mergedOptions.palette.primary)) {
        const palette = mergedOptions.palette.primary;
        const color = tinycolor(palette.main);
        const { h, s } = color.toHsl();
        if (!palette.background) {
            palette.background = mergedOptions.palette.mode !== 'dark' ?
                `hsl(${Math.round(h)}, ${Math.round(s * 80)}%, 90%)` :
                `hsl(${Math.round(h)}, ${Math.round(s * 30)}%, 30%)`;
        }
    }

    return createThemeWithOverrides(mergedOptions, themeOverrides, overrides);
}

function getInitialTheme() {
    if (window.parent !== window) {
        return 'default';
    }
    return window.localStorage.getItem(lsThemeKey) || 'default';
}

export const currentTheme = observable.box<string | undefined>(getInitialTheme());

/** */
export function setCurrentTheme(theme: string = 'default') {
    runInAction(() => {
        currentTheme.set(theme);
        window.localStorage.setItem(lsThemeKey, theme);
    });
}

(window as any)._setTheme = setCurrentTheme;
(window as any)._setThemeCustom = (primary = '#3A81C4', secondary = '#d94f70') => {
    // Try creating theme to validate input
    createTheme({
        palette: {
            primary: { main: primary },
            secondary: { main: primary },
        },
    });
    window.localStorage.setItem(lsThemeKey, 'custom_dark');
    window.localStorage.setItem(lsThemePrimaryKey, primary);
    window.localStorage.setItem(lsThemeSecondaryKey, secondary);
    window.location.reload();
};

export const themes = {
    default: createTheme({
        // status: {
        //     danger: orange[500],
        // },
        palette: {
            background: {
                // default: 'hsl(210, 50%, 98%)',
                default: 'hsl(0, 0%, 93%)',
            },
            primary: {
                main: '#3A81C4',
                dark: '#00396F',
            },
            secondary: {
                main: '#d94f70',
            },
        },
        // typography: {
        //     fontSize: 12,
        // },
    }),
    default_dark: createTheme({
        palette: {
            mode: 'dark',
            background: {
                default: 'hsl(210, 10%, 19%)',
                paper: '#424242',
            },
            primary: {
                main: '#3A81C4',
                dark: '#00396F',
            },
            secondary: {
                main: '#d94f70',
            },
        },
    }),
    default_hc: createTheme({
        palette: {
            mode: 'dark',
            background: {
                default: '#000',
                paper: '#111',
            },
            primary: {
                main: '#FF0',
                light: '#FF0',
                dark: '#FF0',
                background: '#220',
            },
            text: {
                primary: '#FFF',
                secondary: '#FF0',
            },
            action: {
                hover: '#550',
            },
            divider: '#999',
        },
        components: {
            MuiAppBar: {
                styleOverrides: {
                    colorDefault: {
                        backgroundColor: '#000',
                        border: 'none',
                        borderBottom: '1px solid #999',
                    },
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        border: '1px solid #999',
                    }
                }
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#111',
                        border: '1px solid #999',
                    },
                    clickable: {
                        '&:hover, &:focus': {
                            backgroundColor: '#FF0',
                            color: '#000',
                            '& .MuiChip-icon': {
                                color: '#000',
                            }
                        },
                    },
                }
            },
        }
    }),
    lime: createTheme({ palette: { primary: { main: '#c6ff00' }, }, }),
    lime_dark: createTheme({ palette: { primary: { main: '#c6ff00' }, mode: 'dark', }, }),
    pink: createTheme({ palette: { primary: { main: '#ff4081' }, }, }),
    pink_dark: createTheme({ palette: { primary: { main: '#ff4081' }, mode: 'dark', }, }),
    custom: createTheme({
        palette: {
            primary: { main: window.localStorage.getItem(lsThemePrimaryKey) || '#c6ff00' },
            secondary: { main: window.localStorage.getItem(lsThemeSecondaryKey) || '#f50057' },
        },
    }),
    custom_dark: createTheme({
        palette: {
            primary: { main: window.localStorage.getItem(lsThemePrimaryKey) || '#c6ff00' },
            secondary: { main: window.localStorage.getItem(lsThemeSecondaryKey) || '#f50057' },
            mode: 'dark',
        },
    }),
};

const AppThemeProvider = observer((props: { children: React.ReactNode; theme?: string; }) => {
    React.useEffect(() => {
        window.document.body.style.setProperty('--theme-transition-duration', '200ms');
    });

    const themeObj = themes[(props.theme || currentTheme) as keyof typeof themes] || themes.default;

    // Clear startup color override
    document.body.style.background = '';
    // Store color for startup override
    localStorage.setItem('theme-bg-color', themeObj.palette.background.default);

    return (
        <StyledEngineProvider>
            <ThemeProvider theme={themeObj}>
                <GlobalStyles styles={{
                    /* This fixes a weird chrome bug where sometimes color on links would sometimes not be inherited correctly  */
                    ':where(a:any-link > *)': {
                        color: 'inherit',
                        textDecoration: 'inherit',
                    }
                }} />
                {props.children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
});

export default AppThemeProvider;
