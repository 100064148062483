import { observable, runInAction } from 'mobx';

// IMPORTANT: This file is ONLY for reporting application load state before the application bootstrap finished for use in AppLoader!

type AppLoadError = undefined | 'network' | 'api' | 'no_teams';

export const appLoadError = observable.box<AppLoadError>();

/** */
export function setAppLoadError(type: AppLoadError) {
    runInAction(() => {
        appLoadError.set(type);
    });
}
