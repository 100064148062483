// @description see default export

const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'] as const;

declare global {
    interface Navigator {
        browserLanguage?: string;
        systemLanguage?: string;
        userLanguage?: string;
    }
}

/** Tries to get the correct active browser language out of a set of usable languages */
export default function getFirstBrowserLanguage(availableLanguages: string[]) {
    const availableLangs = availableLanguages.map(l => l.split('-'));

    // eslint-disable-next-line jsdoc/require-jsdoc
    function checkLang(lang?: string) {
        if (typeof lang !== 'string') {
            return;
        }
        const parts = lang.split('-');
        let language = availableLangs.find(l => l[0] === parts[0] && l[1] === parts[1]);
        if (!language) {
            language = availableLangs.find(l => l[0] === parts[0]);
        }
        return language ? language.join('-') : undefined;
    }

    // support for HTML 5.1 "navigator.languages"
    if (Array.isArray(availableLanguages) && Array.isArray(window.navigator.languages)) {
        for (const lang of window.navigator.languages as string[]) {
            const language = checkLang(lang);
            if (language) {
                return language;
            }
        }
    }
    // support for other well known properties in browsers
    for (const key of browserLanguagePropertyKeys) {
        const language = checkLang(window.navigator[key]);
        if (language) {
            return language;
        }
    }
}
